(function() {
    const handleMessage = function(e) {
        const parentDomain = e.origin.replace(/(http(s)?:\/\/)/, '').split('/')[0];
        const allowedDomains = window.initialData ? window.initialData['allowedDomains'] : false;
        const isAllowed = !allowedDomains || allowedDomains.reduce((acc, allowedDomain) => {
            return acc || (parentDomain === allowedDomain ||
                parentDomain === allowedDomain + '/' ||
                (allowedDomain.indexOf('.') === 0 && parentDomain.indexOf(allowedDomain.substr(1)) !== -1));
        }, false);

        if (!isAllowed) {
            return;
        }

        try {
            const data = e.data;
            console.log(data);
        } catch (err) {
            console.log(err);
            console.log('Data not handled.');
        }
    };

    if (window.addEventListener) {
        window.addEventListener('message', handleMessage, false);
    } else if (window.attachEvent) {
        window.attachEvent('on' + 'message', handleMessage);
    }

    window.onload = function() {
        // Get the height of the content
        const body = document.body,
        html = document.documentElement;

        if (window.self !== window.top) {
            document.addEventListener('click', function(event) {
                var target = event.target;
                
                while (target !== document) {
                    if (target.tagName === 'A') {
                        var href = target.getAttribute('href');
                        
                        // Check if the href is an internal link
                        if (href.startsWith('/') || href.startsWith(window.location.origin)) {
                            event.preventDefault();
                            
                            window.parent.postMessage({
                                type: 'INTERNAL_LINK_CLICKED',
                                url: href
                            }, '*'); // Replace '*' with the actual origin of the parent page for security
            
                            // Break out of the loop
                            return;
                        }
                    }
            
                    // Move up to the parent node
                    target = target.parentNode;
                }
            });
        }

        const height = Math.max( body.scrollHeight, body.offsetHeight, 
                            html.clientHeight, html.scrollHeight, html.offsetHeight );

        // Send a message to the parent page
        window.parent.postMessage({
            type: 'SET_IFRAME_HEIGHT',
            height: height
        }, '*'); // Ideally, instead of '*', specify the origin of the parent page for added security

        window.parent.postMessage({
            type: 'IFRAME_URL_CHANGE',
            path: window.location.pathname
        }, '*');
    };
})();